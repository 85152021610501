import React, { useEffect, lazy, useRef } from 'react';
import Routes from './routes/routes';
import styles from "./App.module.scss";
import useAuthStore from 'stores/AuthStore';
import { Text } from "@mantine/core";


import { Modal } from "utils/modals";
const LoginTokenExpiredModal = lazy(() => import('atoms/Modals/LoginTokenExpiredModal'));

function App() {
	let interval = useRef(null);
	const AuthStore = useAuthStore();

	useEffect(() => {
		const interval = setInterval(async () => {
			if (AuthStore.isLogin() && AuthStore.isTokenExpire()) {
				Modal.open(<LoginTokenExpiredModal />, {
					title: <Text fw="bold">Session Expired</Text>,
					withCloseButton: true,
					closeOnClickOutside: false,
				});
			}
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		// interval.current = setInterval(() => {
		// if (AuthStore.isLogin() && AuthSture.isTokenExpire()) {
		// if (AuthStore.isLogin() && AuthSture.isTokenExpire()) {
		// logout.logout();
		// Modal.open(<LoginTokenExpiredModal />)

		// }

		// }, 1000);

		// return () => clearInterval(interval.current);
	}, [])
	return (
		<div className={styles['app']} >
			<Routes />
		</div>
	);
}

export default App;
