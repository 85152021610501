import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { LocalStorage } from 'utils/localStorage';

//ts-
const STORE_KEY = "wo-user-store";
type Store = {
    user: any,
    setState: (key: string, value: any) => void;
    updateUser: (value: any) => void;
    getUserProfilePic: () => string;
    resetState: () => void;
};
let initialState = {
    user: {},
};
// @ts-ignore
initialState = LocalStorage.initStore(STORE_KEY, initialState)
const useUserStore = create<Store>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                getUserProfilePic: () => {
                    let url = get().user?.image_remote_url;
                    if (url && `${url}`.trim() != '') {
                        return url;
                    }
                    return require('assets/images/default-profile-picture.jpeg');
                },
                getUserFirstName: () => {
                    const [firstName] = get().user?.name.split(' ');
                    return firstName
                },
                setState: (key, value) => {
                    set((s) => ({ [key]: value }));
                },
                updateUser: (value) => {
                    set((s) => {
                        return {
                            user: {
                                ...s.user,
                                ...value
                            }
                        };
                    });
                },
                resetState: () => {
                    set((s) => initialState);
                    LocalStorage.remove(STORE_KEY);
                },
            }),
            {
                name: STORE_KEY,
                storage: createJSONStorage(() => localStorage),
                version: 1,
            }
        )
    )
);
export default useUserStore;
