import React from 'react';
import './app-config';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import AppWrapper from './AppWrapper';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleClientId } from 'utils/constants/social_key';
import 'index.module.scss';

const root = createRoot(document.getElementById('root')! as HTMLElement);

root.render(
	<React.Fragment>
		<GoogleOAuthProvider clientId={googleClientId}>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					fontFamily: 'roboto, sans-serif',
					headings: { fontFamily: 'roboto, sans-serif' },
				}}
			>
				<AppWrapper />
			</MantineProvider>
		</GoogleOAuthProvider>
	</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
