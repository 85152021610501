import React, { lazy, useContext, useRef, useEffect } from 'react';
import {
    useRoutes,
    Navigate,
    useNavigate,
    useLocation,
    useSearchParams,
    useParams,
} from 'react-router-dom';
import { LocalStorage } from 'utils/localStorage';
import useUserStore from 'stores/UserStore';
import useAuthStore from 'stores/AuthStore';

const Home = lazy(() => import('screens/Home/Home'));
const GolfLogin = lazy(() => import('screens/GolfBookingScreens/GolfLogin'));
const VenueDetails = lazy(() => import('screens/Venue/VenueDetails'));
const CustomerTickets = lazy(() => import('screens/Tickets/CustomerTickets'));
const LikedEvents = lazy(() => import('screens/Events/LikedEvents'));
const CustomerAccount = lazy(() => import('screens/Account/Account'));
const HotelHome = lazy(() => import('screens/HotelHome/HotelHome'));
const HotelSearchResult = lazy(() => import('screens/HotelSearchResult/HotelSearchResult'));
const HotelDetails = lazy(() => import('screens/HotelDetails/HotelDetails'));
const RestaurantHome = lazy(() => import('screens/RestaurantHome/RestaurantHome'));
const Account = lazy(() => import('screens/Account/Account'));
const Bookings = lazy(() => import('screens/Bookings/Bookings'));
const BookingPayment = lazy(() => import('screens/Bookings/BookingPayment'));
const Login = lazy(() => import('screens/Login/Login'));
const Signup = lazy(() => import('screens/Signup/Signup'));
const Error404 = lazy(() => import('screens/Error404/Error404'));
const BlogDetails = lazy(() => import('screens/Blog/BlogDetails'));
const GolfBooking = lazy(() => import('screens/GolfBookingScreens/GolfBooking'));
const GolfBooked = lazy(() => import('screens/GolfBookingScreens/GolfBooked'));
const BookingCheckinScreen = lazy(() => import('screens/SLBBookings/BookingCheckinScreen'));

function Routes() {
    const navigate = useNavigate();
    const location = useLocation();
    const UserStore = useUserStore((s) => s);
    const AuthStore = useAuthStore();
    const rootRoutePath = location.pathname.split('/')[1];
    const userPermissions = UserStore.user?.role_details?.permissions || [];

    const isAccessibleRoute = () => {
        // if (process.env.NODE_ENV === "production") {
        //     const currentPath = location.pathname;
        //     return !["/"].some(route => {
        //         const regex = new RegExp(`^${route}(\/\\d+)*$`);
        //         console.log(route, 'da routee')
        //         return regex.test(currentPath);
        //     });
        // }
        return true
    };

    useEffect(() => {
        if (!AuthStore.isLogin() && location.pathname != "/signup") {
            // navigate('/login');
            // LocalStorage.clear();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AuthStore.isLogin()]);

    useEffect(() => {
        // if (AuthStore.isLogin()) {
        //     const init = async () => {
        //         if (!isAccessibleRoute()) {
        //             // navigate('/404');
        //             return;
        //         }
        //     };
        //     init();
        // }
        const isAccessible = isAccessibleRoute()
        console.log(isAccessible, 'ksjkwjelqkj')
        if (!isAccessible) {
            // navigate('/404');
            navigate('/404', { replace: true });
            return;
        }


    }, [location.pathname, location, navigate]);


    const routes = useRoutes([

        { path: '/', element: <Error404 /> },
        { path: '/:booking_id', element: <BookingCheckinScreen /> },
        { path: '/:booking_id/:ref_number', element: <BookingCheckinScreen /> },
        // { path: '/venue/:venue_id', element: <VenueDetails /> },

        { path: '/404', element: <Error404 /> },
        { path: '*', element: <Navigate to="/404" replace={true} /> },
    ]);

    return <>
        {routes}

    </>;
}
export default Routes;
